<template>
  <div v-show="!showModal">
    <a-spin :spinning="loading">
      <a-form ref="form" :model="formState" name="form" @finish="onSearch">
        <!-- 搜索栏 -->
        <a-row>
          <a-form-item label="礼包名称" name="title" class="ui-form__item">
            <a-input v-model:value="formState.title" placeholder="请输入礼包名称"></a-input>
          </a-form-item>

          <a-form-item label="状态" name="isDisabled" class="ui-form__item">
            <a-select v-model:value="formState.isDisabled" placeholder="请选择状态" allow-clear style="width: 180px;">
              <a-select-option :value="0">启用</a-select-option>
              <a-select-option :value="1">禁用</a-select-option>
            </a-select>
          </a-form-item>
        </a-row>
		
        <a-row>
          <a-col :span="18">
            <a-button v-permission="['coupon_giftPack_list_add']" type="primary" @click="onAdd">新增礼包</a-button>
          </a-col>
          <a-col :span="6" style="text-align: right">
            <a-button
              style="margin-right: 10px"
              type="primary"
              html-type="submit"
            >搜索</a-button
            >
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>

      <div style="margin-top: 20px;">
        <a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
                 :scroll="{ x: 950 }">
          <template #bodyCell="{ column, record }">
			  <template v-if="column.key === 'image'">
				  <a-image :src="record.image" style="width: 50px;"></a-image>
			  </template>
            <template v-if="column.key === 'isDisabled'">
              <a-tag v-if="!record.isDisabled" color="#87d068">启用</a-tag>
              <a-tag v-if="record.isDisabled" color="#f50">禁用</a-tag>
            </template>
            <template v-if="column.key === 'action'">
              <a-dropdown :trigger="['click', 'hover']">
                <a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <div v-permission="['coupon_giftPack_list_edit']" @click="onEdit(record)">
                      <a-menu-item>
                        编辑
                      </a-menu-item>
                    </div>
                    <div v-permission="['coupon_giftPack_list_open']" v-if="record.isDisabled" @click="onDisabled(record)">
                      <a-menu-item>
                        启用
                      </a-menu-item>
                    </div>
                    <div v-permission="['coupon_giftPack_list_close']" v-else @click="onDisabled(record)">
                      <a-menu-item>
                        禁用
                      </a-menu-item>
                    </div>
                    <div v-if="!record.isDisabled" v-permission="['coupon_giftPack_list_send']" @click="this.id = record.id">
                      <a-menu-item>
                        <sendGift :id="id" v-model:value="userListId"></sendGift>
                      </a-menu-item>
                    </div>
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
          </template>
        </a-table>
      </div>
    </a-spin>

  </div>
  <temp v-if="showModal" :isEdit="isEdit" :id="id" @back="onBack"></temp>
</template>

<script>
import temp from './temp.vue'
import {
  Icon
} from '@/components/icon/icon.js';
import sendGift from '@/components/sendGift/index.vue'
import { getAppCouponPacketList, updateStatusAppCouponPacket } from "@/service/modules/coupon";
export default {
  components: {
    Icon,
    temp,
    sendGift
  },
  data() {
    return {
      showModal: false,
      isEdit: false,
      id: 0,
      loading: false,
      showAll: false,
      userListId: [],
      searchData: {},
      formState: {},
      columns: [{
        title: '礼包名称',
        dataIndex: 'title',
      }, {
		  title: '图片',
		  key: 'image'
	  }, {
        title: '售价',
        dataIndex: 'salePrice',
      }, {
        title: '限购数',
        dataIndex: 'limitNum',
      }, {
        title: '剩余库存',
        dataIndex: 'stockNum',
      }, {
        title: '已售',
        dataIndex: 'saleNum',
      }, {
        title: '含券数',
        dataIndex: 'couponNum',
      }, {
        title: '优先级',
        dataIndex: 'sort',
      }, {
        title: '状态',
        key: 'isDisabled',
      }, {
        title: '操作',
        key: 'action',
      }],
      list: [],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ['10','20', '50', '100', '500'],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
    }
  },
  created() {
    this.getData();
  },
  methods: {
    reset() {
      this.$refs.form.resetFields();
      this.onSearch();
    },
    onSearch() {
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    onAdd() {
      this.id = 0;
      this.isEdit = false;
      this.showModal = true;
    },
    onEdit(item) {
      this.id = item.id;
      this.isEdit = true;
      this.showModal = true;
    },
    onBack(isRef) {
      this.showModal = false;
      if (isRef) {
        this.$nextTick(() => {
          this.getData();
        })
      }
    },
    async getData() {
      this.loading = true;
      try {
        let ret = await getAppCouponPacketList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData
        })
        this.loading = false;
        if(ret.code === 200) {
          this.list = ret.data.list;
          this.pagination.total = ret.data.totalCount;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    onDisabled(item) {
      this.$confirm({
        title: '提示',
        content: '确认' + (item.isDisabled ? '启用' : '禁用') + '该礼包吗？',
        onOk: async () => {
          this.loading = true;
          try {
            let ret = await updateStatusAppCouponPacket({
              id: item.id,
              isDisabled: item.isDisabled ? 0 : 1
            })
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success('操作成功');
              this.getData();
            }
          } catch(e) {
            this.loading = false;
          }
        }
      })
    },
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
